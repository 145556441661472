import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex, Select } from '@mantine/core';
import { Calendar, DatePickerInput } from '@mantine/dates';

import { isNotTransientProps } from '~/utils';

export const Root = styled(Box)`
  padding: 24px 0;

  & + & {
    border-top: 1px solid ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
  }
`;

export const ChevronDownIcon = styled(Box)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_NORMAL};
  transition: transform 300ms ease-in-out;
`;

export const SearchIcon = styled(Box)`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_WEAKEST};
`;

export const ThreeBarsIcon = styled(Box)`
  width: 10px;
  height: 14px;
  flex-shrink: 0;
  color: ${({ theme }) => theme.app.colors.ICON_NEUTRAL_WEAKEST};
`;

export const StyledCalender = styled(Calendar)`
  width: 100%;

  button {
    border-radius: 8px;
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;

    > span {
      color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_WEAKEST};
    }
  }
`;

export const StyledDatePickerInput = styled(DatePickerInput)`
  button {
    border-radius: 8px;
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;

    > span {
      color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_WEAKEST};
    }
  }
`;

export const StyledSelect = styled(Select)`
  input {
    border-radius: 8px;
    font-size: 11px;
    line-height: 11px;
    font-weight: 400;

    &::placeholder {
      color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_WEAKEST};
    }
  }
`;

export const MaxMinValueContainer = styled(Box)`
  flex: 1;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
  border-radius: 8px;
`;

export const PresetDateFiltersContainer = styled(Flex)`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
  border-radius: 4px;
`;

export const PresetDateFilter = styled(Flex, { shouldForwardProp: isNotTransientProps })`
  justify-content: center;
  flex: 1;
  padding: 10px 16px;
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_WEAK};
  cursor: pointer;

  ${({ selected, theme }) =>
    selected
      ? css`
          background-color: ${theme.app.colors.BG_NEUTRAL_WEAKEST};
          outline: 1px solid ${theme.app.colors.BORDER_NEUTRAL_WEAKEST};
          border-radius: 2px;
        `
      : null}

  & + & {
    border-left: 1px solid ${({ theme }) => theme.app.colors.BORDER_NEUTRAL_WEAKEST};
  }
`;
