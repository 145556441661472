import queryString from 'query-string';
import { format, isDate } from 'date-fns';

import { DATE_FORMATS, JOB_ANALYTICS_TYPES } from '~/constants';

import httpClient from './client';

const isValidDateRangeValues = (values = []) => {
  return Array.isArray(values) && isDate(values[0]) && isDate(values[1]);
};

export const fetchJobsForOrg = ({ orgId, pageNumber, pageSize, status, apiVersion = 'v2' }, options = {}) => {
  const query = queryString.stringify({ orgId, pageNumber, pageSize, status });

  return httpClient.get(`/api/${apiVersion}/job?` + query, options);
};

export const fetchJobDetails = ({ jobSlug, apiVersion = 'v2' }, options = {}) => {
  return httpClient.get(`/api/${apiVersion}/job/${jobSlug}/`, options);
};

export const fetchFiltersForJob = ({ jobId, payload, apiVersion = 'v2' }, options = {}) => {
  const queryObj = {
    ...payload,
  };

  if (queryObj.workExperienceInYears?.length && Array.isArray(queryObj.workExperienceInYears)) {
    queryObj['workExperienceInYears.comparison'] = 'between';
    queryObj['workExperienceInYears.values'] = queryObj.workExperienceInYears.join(',');
  }

  if (isValidDateRangeValues(queryObj.sourcedAt)) {
    queryObj['sourcedAt.comparison'] = 'between';
    queryObj['sourcedAt.values'] = queryObj.sourcedAt.map((date) => format(date, DATE_FORMATS.csvDate)).join(',');
  }

  delete queryObj['workExperienceInYears'];
  delete queryObj['sourcedAt'];

  // TODO: Handle last interaction date range
  const query = queryString.stringify(queryObj);

  return httpClient.get(`/api/${apiVersion}/job/${jobId}/filters?` + query, options);
};

// V1 APIS
export const saveJobConfig = ({ jobId, payload, apiVersion = 'v1' }, options = {}) => {
  return httpClient.post(`/api/${apiVersion}/job/${jobId}/action-config/`, payload, options);
};

export const updateJobConfig = ({ jobId, payload, apiVersion = 'v1' }, options = {}) => {
  return httpClient.patch(`/api/${apiVersion}/job/${jobId}/action-config/`, payload, options);
};

export const fetchJobSearchHistory = ({ jobId, pageNumber, pageSize, apiVersion = 'v1' }, options = {}) => {
  const query = queryString.stringify({ pageNumber, pageSize });

  return httpClient.get(`/api/${apiVersion}/job/${jobId}/search-history/?` + query, options);
};

export const updateJobSearchHistory = ({ jobId, searchHistoryId, payload, apiVersion = 'v1' }, options = {}) => {
  return httpClient.patch(`/api/${apiVersion}/job/${jobId}/search-history/${searchHistoryId}`, payload, options);
};

export const fetchJobSearchHistoryById = ({ searchHistoryId, apiVersion = 'v1' }, options = {}) => {
  return httpClient.get(`/api/${apiVersion}/job/search-history/${searchHistoryId}`, options);
};

export const deletePinFromJob = ({ jobId, apiVersion = 'v1' }, options = {}) => {
  return httpClient.delete(`/api/${apiVersion}/job/${jobId}/pin`, options);
};

export const pinJob = ({ jobId, apiVersion = 'v1' }, options = {}) => {
  return httpClient.post(`/api/${apiVersion}/job/${jobId}/pin`, options);
};

export const fetchPinnedJobs = ({ apiVersion = 'v1' }, options = {}) => {
  return httpClient.get(`/api/${apiVersion}/job/pinned/`, options);
};

export const fetchAllJobs = ({ apiVersion = 'v1', query = '' }, options = {}) => {
  return httpClient.get(`/api/${apiVersion}/job/all?title=${query}`, options);
};

export const fetchJobAnalytics = ({ jobId, type = JOB_ANALYTICS_TYPES.CANDIDATES_APPLIED, sourcedAt, apiVersion = 'v1' }, options = {}) => {
  const queryObj = { type };

  if (sourcedAt) {
    queryObj['sourcedAt.comparison'] = 'between';
    queryObj['sourcedAt.values'] = sourcedAt.values.join(',');
  }

  const query = queryString.stringify(queryObj);

  return httpClient.get(`/api/${apiVersion}/job/${jobId}/analytics?` + query, options);
};
