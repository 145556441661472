import { Box, Flex, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import snakecaseKeys from 'snakecase-keys';
import { format, isToday, isYesterday } from 'date-fns';
import { groupBy } from 'lodash-es';

import { extractResponseError } from '~/api/utils';
import { PageContentLoader } from '~/components/molecules';
import { useRequestStates } from '~/hooks';
import { generateSearchQueryAttributes } from '~/utils/analytics';
import { LabelMediumStrong, ParaXSmall, ParaXSmallStrong, TitleMedium, TitleXSmall } from '~/components/typography';
import { notifyError, notifySuccess } from '~/utils/notifications';
import { DATE_FORMATS, NON_FILTER_INPUT_KEYS_FOR_SEARCH } from '~/constants';
import { Button, Tabs } from '~/components/atoms';

import NoResultsIllustration from '~/assets/images/modals/search-history-modal/no-results.svg';
import HistoryIcon from '~/assets/icons/history.svg';
import CrossIcon from '~/assets/icons/cross.svg';

import * as Styles from './PromptSearchHistoryModal.styled';
import SearchHistory from './component/SearchHistory/SearchHistory';

const SEARCH_HISTORY_TABS = {
  ALL: 'ALL',
  SAVED: 'SAVED',
};

const PromptSearchHistoryModal = ({ innerProps, context, id }) => {
  const theme = useMantineTheme();
  const { job, onUseQuery, trackEvent, fetchResultsApi, updateSearchHistoryApi, filters } = innerProps;
  const [pageNumber, setPageNumber] = useState(1);
  const [searchHistories, setSearchHistories] = useState([]);
  const [selectedTab, setSelectedTab] = useState(SEARCH_HISTORY_TABS.ALL);
  const [fetchSearchHistoryRequestStates, fetchSearchHistoryRequestHandlers] = useRequestStates();
  const [loadMoreSearchHistoryRequestState, loadMoreSearchHistoryRequestHandlers] = useRequestStates();
  const [saveTalentPoolHistoryRequestStates, saveTalentPoolHistoryRequestHandlers] = useRequestStates();

  const formatDate = (date) => {
    let formattedDate = format(date, DATE_FORMATS.dateWithDay);
    let prefix = '';
    if (isToday(date)) {
      prefix = 'Today, ';
    } else if (isYesterday(date)) {
      prefix = 'Yesterday, ';
    }
    return prefix + formattedDate;
  };

  const handleUseQuery = ({ searchHistory, sequence }) => {
    const { parameters } = searchHistory;

    const appliedFilterKeys = Object.keys(snakecaseKeys(searchHistory.parameters)).filter(
      (parameter) => !NON_FILTER_INPUT_KEYS_FOR_SEARCH.includes(parameter),
    );

    trackEvent('attempted_to_use_search_history', {
      search: generateSearchQueryAttributes(searchHistory),
      search_filters: appliedFilterKeys,
      section: 'search_history',
    });

    onUseQuery({ formData: parameters, searchHistory, sequence });
    context.closeModal(id);
  };

  const handleToggleSaveSearchHistory = async (searchHistory) => {
    try {
      saveTalentPoolHistoryRequestHandlers.pending();
      const payload = {
        isSaved: !searchHistory.isSaved,
      };
      const resp = await updateSearchHistoryApi({
        searchHistoryId: searchHistory.id,
        jobId: job?.id,
        payload,
      });
      saveTalentPoolHistoryRequestHandlers.fulfilled(resp.result);
      const { isSaved: isSearchHistorySaved } = resp.result;
      const saveSuccessMessage = 'Saved the search.';
      const unsaveSuccessMessage = 'Unsaved the search.';
      notifySuccess({
        message: isSearchHistorySaved ? saveSuccessMessage : unsaveSuccessMessage,
      });

      const appliedFilterKeys = Object.keys(snakecaseKeys(searchHistory.parameters)).filter(
        (parameter) => !NON_FILTER_INPUT_KEYS_FOR_SEARCH.includes(parameter),
      );

      trackEvent('attempted_to_save_search_history', {
        search: generateSearchQueryAttributes(searchHistory),
        search_filters: appliedFilterKeys,
        section: 'search_history',
      });

      setSearchHistories((histories) => {
        const updatedHistoryIdx = histories.findIndex((history) => history.id === searchHistory.id);
        const updatedHistories = [...histories];
        updatedHistories[updatedHistoryIdx] = resp.result;
        return updatedHistories;
      });
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      saveTalentPoolHistoryRequestHandlers.rejected(errorMessage);
      notifyError({
        message: 'Something went wrong. Please try again later.',
      });
    }
  };

  const fetchSearchHistory = async () => {
    const requestHandler = pageNumber > 1 ? loadMoreSearchHistoryRequestHandlers : fetchSearchHistoryRequestHandlers;

    try {
      requestHandler.pending();
      const payload = {
        pageNumber: pageNumber,
        pageSize: 5,
      };
      if (job) {
        payload.jobId = job.id;
      }
      const resp = await fetchResultsApi(payload);
      const { history, totalCount } = resp.result;
      setSearchHistories((p) => [...p, ...history]);
      setPageNumber((p) => p + 1);
      requestHandler.fulfilled({ history, totalCount });
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      requestHandler.rejected(errorMessage);
    }
  };

  const handleCloseModal = () => {
    context.closeModal(id);
    trackEvent('closed_modal', {
      modal: 'search_history_modal',
    });
  };

  const handleChangeTab = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    fetchSearchHistory();
  }, []);

  let nodeToRender;

  if (fetchSearchHistoryRequestStates.fulfilled) {
    let listNode;
    let loadMoreBtnNode;
    let loaderNode;

    if (loadMoreSearchHistoryRequestState.pending) {
      loaderNode = <PageContentLoader />;
    }

    const searchHistoriesToDisplay =
      selectedTab === SEARCH_HISTORY_TABS.ALL ? searchHistories : searchHistories.filter((history) => history.isSaved);

    if (
      searchHistoriesToDisplay.length &&
      searchHistoriesToDisplay.length <
        (fetchSearchHistoryRequestStates.data?.totalCount || loadMoreSearchHistoryRequestState.data?.totalCount)
    ) {
      loadMoreBtnNode = (
        <Flex justify="center" mt="24px">
          <Button
            color={Button.COLORS.ACCENT}
            size={Button.SIZES.X_SMALL}
            variant={Button.VARIANTS.OUTLINED}
            onClick={fetchSearchHistory}
            loading={loadMoreSearchHistoryRequestState.pending}
            text="Load More"
          />
        </Flex>
      );
    }

    if (selectedTab === SEARCH_HISTORY_TABS.ALL) {
      const searchHistoriesByDate = groupBy(searchHistoriesToDisplay, (history) =>
        formatDate(new Date(history.updatedAt)),
      );

      listNode = Object.keys(searchHistoriesByDate).map((dateOfGroup, idx) => (
        <Styles.TabPanelContent>
          <LabelMediumStrong mb="42px">{dateOfGroup}</LabelMediumStrong>
          {searchHistoriesByDate[dateOfGroup].map((searchHistory) => (
            <SearchHistory
              key={searchHistory.id}
              filters={filters}
              searchHistory={searchHistory}
              saveTalentPoolHistoryRequestStates={saveTalentPoolHistoryRequestStates}
              onUseQuery={() => handleUseQuery({ searchHistory, sequence: idx + 1 })}
              onToggleSave={() => handleToggleSaveSearchHistory(searchHistory)}
            />
          ))}
        </Styles.TabPanelContent>
      ));
    } else if (selectedTab === SEARCH_HISTORY_TABS.SAVED) {
      listNode = (
        <Styles.TabPanelContent>
          {searchHistoriesToDisplay.map((searchHistory, idx) => (
            <SearchHistory
              key={searchHistory.id}
              filters={filters}
              searchHistory={searchHistory}
              saveTalentPoolHistoryRequestStates={saveTalentPoolHistoryRequestStates}
              onUseQuery={() => handleUseQuery({ searchHistory, sequence: idx + 1 })}
              onToggleSave={() => handleToggleSaveSearchHistory(searchHistory)}
            />
          ))}
        </Styles.TabPanelContent>
      );
    }

    const tabPanelNode = (
      <>
        <Box>
          {searchHistoriesToDisplay.length ? (
            listNode
          ) : (
            <Flex
              justify="center"
              align="center"
              style={{
                flexDirection: 'column',
              }}
            >
              <Styles.NoResultsIllustrationWrapper>
                <NoResultsIllustration />
              </Styles.NoResultsIllustrationWrapper>
              <Box mt="48px">
                <TitleMedium ta="center">It’s Empty in Here</TitleMedium>
                <ParaXSmall c={theme.app.colors.TEXT_NEUTRAL_WEAK} mt="12px" ta="center">
                  You’ll find all your history of searches here as you do it
                </ParaXSmall>
              </Box>
            </Flex>
          )}
        </Box>
        {loaderNode}
        {loadMoreBtnNode}
      </>
    );

    nodeToRender = (
      <>
        <Tabs value={selectedTab} onChange={handleChangeTab}>
          <Tabs.List px="24px" style={{ borderBottom: `1px solid ${theme.app.colors.BORDER_NEUTRAL_WEAKEST}` }}>
            <Tabs.Tab value={SEARCH_HISTORY_TABS.ALL}>All</Tabs.Tab>
            <Tabs.Tab value={SEARCH_HISTORY_TABS.SAVED}>Saved</Tabs.Tab>
          </Tabs.List>
          <Styles.TabPanelContainer>
            <Tabs.Panel value={SEARCH_HISTORY_TABS.ALL}>{tabPanelNode}</Tabs.Panel>
            <Tabs.Panel value={SEARCH_HISTORY_TABS.SAVED}>{tabPanelNode}</Tabs.Panel>
          </Styles.TabPanelContainer>
        </Tabs>
      </>
    );
  } else if (fetchSearchHistoryRequestStates.pending) {
    nodeToRender = <PageContentLoader mx="auto" />;
  } else if (fetchSearchHistoryRequestStates.rejected) {
    const errorMessage = fetchSearchHistoryRequestStates.error;

    nodeToRender = (
      <Flex direction="column" align="center" justify="center" py="56px">
        <ParaXSmallStrong c={theme.app.colors.TEXT_NEGATIVE_NORMAL}>
          {errorMessage || 'Failed to load page content. Please try again.'}
        </ParaXSmallStrong>
        <Button mt="24px" size={Button.SIZES.SMALL} onClick={fetchSearchHistory} text="Try again" />
      </Flex>
    );
  }

  return (
    <Styles.Root>
      <Styles.Header>
        <Flex align="center">
          <Styles.HeaderIcon component={HistoryIcon} />
          <TitleXSmall ml="8px">Your Search History</TitleXSmall>
        </Flex>
        <Styles.HeaderIcon
          component={CrossIcon}
          c={theme.app.colors.ICON_NEUTRAL_WEAK}
          style={{ cursor: 'pointer' }}
          onClick={handleCloseModal}
        />
      </Styles.Header>
      <Styles.Body>{nodeToRender}</Styles.Body>
    </Styles.Root>
  );
};

export default PromptSearchHistoryModal;
