import { Avatar, Box, Flex, MantineProvider, useMantineTheme } from '@mantine/core';
import { useState } from 'react';

import { copyToClipboard } from '~/utils';
import { useAppContext } from '~/contexts/app';
import { useIsMobileMedia } from '~/hooks';
import { generateCollectionAttributes } from '~/utils/analytics';
import { LabelSmallStrong, ParaXSmall } from '~/components/typography';
import { Button, Input } from '~/components/atoms';

import ConnectorIconComponent from '~/assets/icons/connector.svg';
import CopyIconComponent from '~/assets/icons/copy.svg';
import NoResultsIllustration from '~/assets/images/modals/invite-teammates-modal/no-results.svg';

import * as Styles from './InviteTeammatesModal.styled';

const InviteTeammatesModal = ({ innerProps }) => {
  const theme = useMantineTheme();
  const isMobileMedia = useIsMobileMedia();

  const { user, organisation, orgUsers } = useAppContext();
  const {
    collaborators,
    collection,
    listOfTeamMembersLabel = 'All Team Members',
    instructionLabel = 'Copy and share the link with your colleagues to invite them to Kello.',
    trackEvent,
  } = innerProps;

  const [isInviteLinkCopied, setIsInviteLinkCopied] = useState(false);

  let invitationUrlPath = '';

  if (collection) {
    invitationUrlPath = `/collections/${collection.id}/invite?utm_campaign=user_invite_from_dashboard&utm_source=copy_link&utm_content=${user.id}`;
  } else {
    invitationUrlPath = `/invite?utm_campaign=user_invite_from_dashboard&utm_source=copy_link&utm_content=${user.id}`;
  }

  const invitationUrl = new URL(location.origin + invitationUrlPath);
  invitationUrl.searchParams.set('organisation', organisation.name);

  const handleCopyLinkBtnClick = async () => {
    copyToClipboard(invitationUrl);
    setIsInviteLinkCopied(true);
    if (collection) {
      trackEvent('copied_invite_link_for_team_to_collection', {
        collection: generateCollectionAttributes(collection),
      });
    } else {
      trackEvent('copied_invite_link_for_team_to_org', {
        team_count: orgUsers.length,
      });
    }
    setTimeout(() => setIsInviteLinkCopied(false), 3000);
  };

  let collaboratorsNode;

  if (collaborators.length) {
    collaboratorsNode = (
      <Styles.TeamMembersContainer cols={isMobileMedia ? 1 : 2} mt="24px">
        {collaborators.map((collaborator) => (
          <Flex align="center" key={collaborator.id}>
            <Avatar
              src={collaborator.picture}
              alt="User Avatar"
              color={theme.app.colors.TEXT_ACCENT_NORMAL}
              bg={theme.app.colors.BG_ACCENT_WEAKEST}
              radius="xl"
            >
              {collaborator.name[0].toUpperCase()}
            </Avatar>
            <Box ml="12px">
              <LabelSmallStrong c={theme.app.colors.TEXT_NEUTRAL_NORMAL}>{collaborator.name}</LabelSmallStrong>
              <ParaXSmall c={theme.app.colors.TEXT_NEUTRAL_WEAK} mt="4px">
                {collaborator.email}
              </ParaXSmall>
            </Box>
          </Flex>
        ))}
      </Styles.TeamMembersContainer>
    );
  } else {
    collaboratorsNode = (
      <Flex justify="center" align="center" mt="24px" style={{ flexDirection: 'column' }}>
        <Styles.NoResultsIllustration component={NoResultsIllustration} />
        <LabelSmallStrong mt="32px" ta="center" c={theme.app.colors.TEXT_NEUTRAL_WEAK}>
          You’re the first one here
        </LabelSmallStrong>
        <ParaXSmall mt="8px" ta="center" c={theme.app.colors.TEXT_NEUTRAL_WEAK}>
          {instructionLabel}
        </ParaXSmall>
      </Flex>
    );
  }

  return (
    <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
      <Box>
        <Flex align="flex-end">
          <Input
            styles={{
              root: {
                flex: 1,
              },
              input: {
                border: `1px solid ${theme.app.colors.BORDER_NEUTRAL_WEAKEST}`,
                background: theme.app.colors.BG_NEUTRAL_WEAKEST,
                paddingLeft: '44px',
                color: theme.app.colors.TEXT_NEUTRAL_WEAK,
              },
              section: {
                paddingLeft: '14px',
              },
            }}
            label="Share this link to invite"
            leftSection={<Styles.ConnectorIcon component={ConnectorIconComponent} />}
            value={invitationUrl.href}
            readOnly
          />
          <Button
            iconLeft={isInviteLinkCopied ? null : <Styles.CopyIcon component={CopyIconComponent} />}
            ml="8px"
            onClick={() => {
              if (!isInviteLinkCopied) {
                handleCopyLinkBtnClick();
              }
            }}
            text={isInviteLinkCopied ? 'Link Copied!' : 'Copy Link'}
          />
        </Flex>
        <LabelSmallStrong mt="32px" c={theme.app.colors.TEXT_NEUTRAL_STRONG}>
          {listOfTeamMembersLabel}
        </LabelSmallStrong>
        {collaboratorsNode}
      </Box>
    </MantineProvider>
  );
};

export default InviteTeammatesModal;
