import { Box, Checkbox, Collapse, Flex, List, RangeSlider, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { isNumber, noop } from 'lodash-es';
import { useEffect, useState } from 'react';
import { differenceInDays, subDays } from 'date-fns';

import { Input } from '~/components/atoms';
import { formatNumberWithCommas, isValidDate } from '~/utils';
import { LabelXSmall, LabelXXSmall, ParaMedium, ParaSmall, ParaSmallStrong, ParaXSmall } from '~/components/typography';

import ChevronDownIcon from '~/assets/icons/chevron-down.svg';
import SearchIconComponent from '~/assets/icons/search.svg';
import ThreeBarsIconComponent from '~/assets/icons/three-bars.svg';

import * as Styles from './index.styled';

const PRESET_DATE_FILTERS = {
  ANY_TIME: {
    label: 'Anytime',
    value: 'ANY_TIME',
  },
  TODAY: {
    label: 'Today',
    value: 'TODAY',
  },
  SEVEN_DAYS: {
    label: '7D',
    value: 'SEVEN_DAYS',
  },
  THIRTY_DAYS: {
    label: '30D',
    value: 'THIRTY_DAYS',
  },
  CUSTOM: {
    label: 'Custom',
    value: 'CUSTOM',
  },
};

const Filter = ({
  isOpened = false,
  title,
  placeholder,
  options = [],
  value,
  valueUnit,
  minInputForRange = 0,
  maxInputForRange = 100,
  minRangeGap = 1,
  isMultiSelect,
  isSelect,
  isDatePicker,
  isRange,
  fetchFiltersRequestStates,
  onChange = noop,
}) => {
  const theme = useMantineTheme();

  let numberOfAppliedFilters = 0;

  if (value) {
    if (isRange || isDatePicker) {
      let isFilterApplied = false;

      if (Array.isArray(value) && value.length) {
        isFilterApplied = value.every((val) => !isNaN(val) || isValidDate(val));
      }

      if (isFilterApplied) {
        numberOfAppliedFilters = 1;
      }
    } else if (isSelect) {
      numberOfAppliedFilters = 1;
    } else if (isMultiSelect) {
      numberOfAppliedFilters = value.length;
    }
  }

  const [opened, { toggle }] = useDisclosure(isOpened || numberOfAppliedFilters > 0);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPresetDateFilter, setSelectedPresetDateFilter] = useState(PRESET_DATE_FILTERS.ANY_TIME);

  const handleChangeForMultiSelect = (optionVal) => {
    const prev = value;
    const selectedOptions = prev.includes(optionVal) ? prev.filter((item) => item !== optionVal) : [...prev, optionVal];
    onChange(selectedOptions);
  };

  const handleChangeForSelect = (optionVal) => {
    onChange(optionVal);
  };

  const handleChangeForDatePicker = (values) => {
    onChange(values);
  };

  const handleChangeForRangeSlider = (values) => {
    if (minInputForRange === values[0] && maxInputForRange === values[1]) {
      onChange([]);
    } else {
      onChange(values);
    }
  };

  const handleChangeSearchInput = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (isDatePicker && value?.length) {
      const startDate = value[0];
      const endDate = value[1];
      const diff = differenceInDays(endDate, startDate);
      let presetVal;
      if (diff === 30) {
        presetVal = PRESET_DATE_FILTERS.THIRTY_DAYS;
      } else if (diff === 7) {
        presetVal = PRESET_DATE_FILTERS.SEVEN_DAYS;
      } else if (diff === 0) {
        presetVal = PRESET_DATE_FILTERS.TODAY;
      } else {
        presetVal = PRESET_DATE_FILTERS.CUSTOM;
      }
      setSelectedPresetDateFilter(presetVal);
    } else {
      setSelectedPresetDateFilter(PRESET_DATE_FILTERS.ANY_TIME);
    }
  }, [isDatePicker, value]);

  let inputNode;
  let numberOfAppliedFiltersNode;

  if (opened) {
    if (isRange) {
      const defaultRanges = [0, maxInputForRange];
      const ranges = value?.every((val) => isNumber(val)) ? value : defaultRanges;
      inputNode = (
        <>
          <RangeSlider
            max={maxInputForRange}
            minRange={minRangeGap}
            value={ranges}
            defaultValue={defaultRanges}
            onChange={handleChangeForRangeSlider}
            thumbSize="32"
            label={null}
            mx="8px"
            styles={{
              thumb: {
                border: `1px solid ${theme.app.colors.BORDER_NEUTRAL_WEAK}`,
                boxShadow: '0px 3px 16px 0px rgba(0, 0, 0, 0.10)',
              },
              bar: {
                height: '1px',
                background: theme.app.colors.BG_BRAND_STRONG,
              },
              track: {
                height: '1px',
                background: theme.app.colors.BG_NEUTRAL_STRONG,
              },
            }}
            thumbChildren={<Styles.ThreeBarsIcon component={ThreeBarsIconComponent} />}
          />
          <Flex mt="24px" justify="space-between" align="center">
            <Styles.MaxMinValueContainer>
              <LabelXSmall>Minimum ({valueUnit})</LabelXSmall>
              <ParaSmallStrong c={theme.app.colors.TEXT_NEUTRAL_STRONG} mt="2px">
                {formatNumberWithCommas(ranges[0])}
              </ParaSmallStrong>
            </Styles.MaxMinValueContainer>
            <Styles.MaxMinValueContainer ml="22px">
              <LabelXSmall>Maximum ({valueUnit})</LabelXSmall>
              <ParaSmallStrong c={theme.app.colors.TEXT_NEUTRAL_STRONG} mt="4px">
                {formatNumberWithCommas(ranges[1])}
              </ParaSmallStrong>
            </Styles.MaxMinValueContainer>
          </Flex>
        </>
      );
    } else if (isSelect) {
      inputNode = (
        <Styles.StyledSelect
          color="dark"
          size="xs"
          placeholder={placeholder}
          searchable
          clearable
          data={options}
          value={value}
          onChange={handleChangeForSelect}
          styles={{
            dropdown: {
              borderRadius: '8px',
            },
            label: {
              fontSize: '14px',
              userSelect: 'none',
              cursor: 'pointer',
            },
            input: {
              border: '0px',
              background: theme.app.colors.BG_NEUTRAL_WEAKEST,
              padding: '16px',
              height: '48px',
              fontSize: '14px',
            },
            option: {
              fontSize: '14px',
              userSelect: 'none',
              cursor: 'pointer',
            },
          }}
          disabled={fetchFiltersRequestStates.pending}
        />
      );
    } else if (isMultiSelect) {
      const filteredOptions = (() => {
        let matchingOptions = options;

        // Filter options based on search term
        if (searchTerm) {
          matchingOptions = options.filter((option) => option.value.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        // Sort options based on selected option
        if (matchingOptions.length && Array.isArray(value)) {
          matchingOptions = [...matchingOptions].sort((a, b) => {
            const isASelected = value.includes(a.value) ? 1 : 0;
            const isBSelected = value.includes(b.value) ? 1 : 0;
            return isBSelected - isASelected;
          });
        }

        return matchingOptions;
      })();

      inputNode = (
        <>
          {options.length > 10 ? (
            <Input
              placeholder={placeholder}
              color={theme.app.colors.TEXT_NEUTRAL_NORMAL}
              value={searchTerm}
              onChange={handleChangeSearchInput}
              leftSection={<Styles.SearchIcon component={SearchIconComponent} />}
              styles={{
                root: {
                  borderRadius: '8px',
                  background: theme.app.colors.BG_NEUTRAL_WEAKEST,
                  paddingLeft: '8px',
                },
                input: {
                  border: '0px',
                  background: theme.app.colors.BG_NEUTRAL_WEAKEST,
                  paddingTop: '16px',
                  paddingBottom: '16px',
                  paddingRight: '16px',
                  paddingLeft: '38px',
                  height: '48px',
                  fontSize: '14px',
                  caretColor: theme.app.colors.BORDER_ACCENT_NORMAL,
                },
              }}
            />
          ) : null}
          <List mah="200px" mt="16px" listStyleType="none" style={{ overflow: 'auto' }}>
            {filteredOptions.length ? (
              filteredOptions.slice(0, 30).map((option) => (
                <List.Item
                  key={option.value}
                  mx="0"
                  p="8px 12px"
                  styles={{
                    itemWrapper: {
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                    },
                  }}
                >
                  <Checkbox
                    color="dark"
                    id={option.value}
                    label={
                      <ParaSmall mt="-2px" c={theme.app.colors.TEXT_NEUTRAL_STRONG}>
                        {option.label}{' '}
                        {/* {isNumber(option.count) ? // TODO: Uncomment later
                          <span style={{ color: theme.app.colors.TEXT_NEUTRAL_WEAK }}>({option.count})</span>
                        ) : null} */}
                      </ParaSmall>
                    }
                    checked={value.includes(option.value)}
                    onChange={() => handleChangeForMultiSelect(option.value)}
                    styles={{
                      label: {
                        fontSize: '14px',
                        userSelect: 'none',
                        cursor: 'pointer',
                      },
                      input: {
                        background: value.includes(option.value) ? theme.app.colors.BG_BRAND_STRONGER : 'inherit',
                        cursor: 'pointer',
                      },
                    }}
                    disabled={fetchFiltersRequestStates.pending}
                  />
                </List.Item>
              ))
            ) : (
              <ParaSmall>Nothing found.</ParaSmall>
            )}
          </List>
        </>
      );
    } else if (isDatePicker) {
      const rangeValue = value?.length ? value : [];

      const getDateRange = (rangeType) => {
        const today = new Date();
        if (rangeType === PRESET_DATE_FILTERS.SEVEN_DAYS.value) {
          return [subDays(today, 7), today];
        } else if (rangeType === PRESET_DATE_FILTERS.THIRTY_DAYS.value) {
          return [subDays(today, 30), today];
        } else if (rangeType === PRESET_DATE_FILTERS.TODAY.value) {
          return [today, today];
        }
        return null;
      };

      const handleSelectPresetFilter = (selected) => {
        setSelectedPresetDateFilter(selected);
        if (selected.value !== PRESET_DATE_FILTERS.CUSTOM.value) {
          const dateRange = getDateRange(selected.value);
          handleChangeForDatePicker(dateRange);
        }
      };

      inputNode = (
        <>
          <Styles.PresetDateFiltersContainer>
            {Object.keys(PRESET_DATE_FILTERS).map((key) => (
              <Styles.PresetDateFilter
                key={PRESET_DATE_FILTERS[key].value}
                selected={selectedPresetDateFilter.value === PRESET_DATE_FILTERS[key].value}
                onClick={() => handleSelectPresetFilter(PRESET_DATE_FILTERS[key])}
              >
                <ParaXSmall>{PRESET_DATE_FILTERS[key].label}</ParaXSmall>
              </Styles.PresetDateFilter>
            ))}
          </Styles.PresetDateFiltersContainer>
          {selectedPresetDateFilter.value === PRESET_DATE_FILTERS.CUSTOM.value ? (
            <Styles.StyledDatePickerInput
              color="dark"
              type="range"
              maxDate={new Date()}
              value={rangeValue}
              clearable
              placeholder={placeholder}
              mt="16px"
              styles={{
                root: {
                  borderRadius: '8px',
                },
                input: {
                  border: '0px',
                  background: theme.app.colors.BG_NEUTRAL_WEAKEST,
                  padding: '16px',
                  height: '48px',
                  fontSize: '14px',
                },
              }}
              onChange={handleChangeForDatePicker}
            />
          ) : null}
        </>
      );
    }
  }

  if (numberOfAppliedFilters) {
    numberOfAppliedFiltersNode = (
      <Flex
        w="18px"
        h="18px"
        bg={theme.app.colors.BG_BRAND_STRONGEST}
        align="center"
        justify="center"
        style={{ borderRadius: '50%' }}
      >
        <LabelXXSmall c={theme.app.colors.TEXT_INVERTED} ta="center">
          {numberOfAppliedFilters}
        </LabelXXSmall>
      </Flex>
    );
  }

  return (
    <Styles.Root>
      <Flex justify="space-between" align="center" style={{ cursor: 'pointer' }} onClick={toggle}>
        <Flex align="center">
          <ParaMedium c={theme.app.colors.TEXT_NEUTRAL_STRONG}>{title}</ParaMedium>
          {numberOfAppliedFiltersNode ? <Box ml="8px">{numberOfAppliedFiltersNode}</Box> : null}
        </Flex>
        <Styles.ChevronDownIcon style={{ transform: opened ? 'rotate(180deg)' : 'unset' }}>
          <ChevronDownIcon />
        </Styles.ChevronDownIcon>
      </Flex>

      <Collapse in={opened}>
        <Box mt="24px">{inputNode}</Box>
      </Collapse>
    </Styles.Root>
  );
};

export default Filter;
