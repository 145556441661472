import { Box, Divider, Flex, Loader, useMantineTheme } from '@mantine/core';
import { useEffect } from 'react';
import { useForm } from '@mantine/form';
import { debounce, isEmpty, noop } from 'lodash-es';
import plur from 'plur';

import { useRequestStates } from '~/hooks';
import { extractResponseError } from '~/api/utils';
import { Button, SkeletonLoader } from '~/components/atoms';
import { LabelXSmall, LabelXXSmall, ParaXSmallStrong, TitleXSmall } from '~/components/typography';
import { BASIC_INPUT_KEYS_FOR_SEARCH, SEARCH_FILTER_INPUT_TYPES } from '~/constants';
import { getTotalCountOfAppliedFilters } from '~/utils';
import { jobApi, jobApplicationApi } from '~/api';

import FilterIcon from '~/assets/icons/filter-v2.svg';
import CrossIcon from '~/assets/icons/close.svg';

import * as Styles from './SearchFiltersModal.styled';
import Filter from './components/Filter';

const SearchFiltersModal = ({ innerProps, context, id }) => {
  const {
    jobId,
    form: inputForm,
    // hideResultsCount = false,
    visibleFilters = [],
    baseFilterValues,
    fetchSearchFilters = jobApi.fetchFiltersForJob,
    fetchResultsCount = jobApplicationApi.fetchFilteredJobApplicationsCount,
    onApplyFilters = noop,
    // onRemoveFilters = noop,
    trackEvent,
  } = innerProps;
  const theme = useMantineTheme();

  const [fetchResultsCountRequestStates, fetchResultsCountRequestHandlers] = useRequestStates();
  const [fetchFiltersRequestStates, fetchFiltersRequestHandlers] = useRequestStates();

  let filters = visibleFilters;

  if (fetchFiltersRequestStates.fulfilled) {
    if (visibleFilters.length) {
      const visibleFilterNames = visibleFilters.map((_filter) => _filter.filterName);

      const availableFilters = fetchFiltersRequestStates.data;
      filters = availableFilters.filter((_filter) => visibleFilterNames.includes(_filter.filterName));
    } else {
      filters = fetchFiltersRequestStates.data;
    }
  }

  const form = useForm({
    initialValues: { ...inputForm.values },
  });

  const getResultsCount = async () => {
    const formValues = form.values;
    const payload = {
      ...baseFilterValues,
    };

    for (const key in formValues) {
      if (key === BASIC_INPUT_KEYS_FOR_SEARCH.PAGE_NUMBER) {
        continue;
      }
      const value = formValues[key];
      if (![null, undefined, ''].includes(value)) {
        payload[key] = value;
      }
    }

    if (jobId) {
      payload.jobId = jobId;
    }
    try {
      fetchResultsCountRequestHandlers.pending();
      const resp = await fetchResultsCount(payload);
      fetchResultsCountRequestHandlers.fulfilled(resp.result);
    } catch (error) {
      const errorObj = extractResponseError(error);
      fetchResultsCountRequestHandlers.rejected(errorObj.errorMessage);
    }
  };

  const getSearchFilters = async ({ filterValues, skipPendingState = true } = {}) => {
    const formValues = filterValues !== undefined ? filterValues : form.values;

    const payload = {
      ...baseFilterValues,
    };

    for (const key in formValues) {
      if (key === BASIC_INPUT_KEYS_FOR_SEARCH.PAGE_NUMBER) {
        continue;
      }
      const value = formValues[key];
      if (![null, undefined, ''].includes(value)) {
        payload[key] = value;
      }
    }

    try {
      if (!skipPendingState) {
        fetchFiltersRequestHandlers.pending();
      }
      const resp = await fetchSearchFilters({ jobId, payload });
      fetchFiltersRequestHandlers.fulfilled(resp.result);
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      fetchFiltersRequestHandlers.rejected(errorMessage);
    }
  };

  const handleApplyFiltersBtnClick = () => {
    context.closeModal(id);
    onApplyFilters(form.values);
  };

  const handleClearAllFilters = () => {
    const filters = form.values;
    for (let key in filters) {
      // TODO: Create a constant for this
      if ([BASIC_INPUT_KEYS_FOR_SEARCH.PAGE_NUMBER, BASIC_INPUT_KEYS_FOR_SEARCH.SEARCH].includes(key)) {
        continue;
      }
      if (Array.isArray(filters[key])) {
        filters[key] = [];
      } else {
        filters[key] = '';
      }
    }

    form.setValues(filters);
  };

  const handleCloseModal = () => {
    context.closeModal(id);
    trackEvent('closed_modal', {
      modal: 'search_filters_modal', // TODO: Discuss the name
    });
  };

  const handleChangeFormValue = (filterName, val) => {
    form.setFieldValue(filterName, val);
    // const filterValues = {
    //   ...form.values,
    //   [filterName]: val,
    // };
    // getSearchFilters({ filterValues });
  };

  const debouncedGetResultsCount = debounce(getResultsCount, 500);

  useEffect(() => {
    if (fetchResultsCount) {
      debouncedGetResultsCount();
    }

    return () => {
      debouncedGetResultsCount.cancel();
    };
  }, [form?.values, fetchResultsCount]);

  // useEffect(() => {
  //   getSearchFilters({
  //     skipPendingState: false,
  //   });
  // }, []);

  const numberOfFiltersApplied = getTotalCountOfAppliedFilters({
    values: form.values,
    filters: filters,
  });
  let numberOfFiltersNode;

  if (numberOfFiltersApplied) {
    numberOfFiltersNode = (
      <Flex
        w="20px"
        h="20px"
        bg={theme.app.colors.BG_BRAND_STRONGEST}
        align="center"
        justify="center"
        style={{ borderRadius: '50%' }}
        mr="8px"
      >
        <LabelXXSmall c={theme.app.colors.TEXT_INVERTED} ta="center">
          {numberOfFiltersApplied}
        </LabelXXSmall>
      </Flex>
    );
  }

  const numberOfCandidatesFound =
    fetchResultsCountRequestStates.data?.count || fetchResultsCountRequestStates.data?.totalCandidates || 0;

  const renderFilter = (filter) => {
    const { inputType, data, filterName, filterLabel } = filter;

    switch (inputType) {
      case SEARCH_FILTER_INPUT_TYPES.MULTI_CHOICE:
        return (
          <Filter
            key={filterName}
            isOpened={filter.isOpened}
            title={filterLabel}
            placeholder={`Select ${filterLabel}`}
            isMultiSelect
            options={data}
            value={form.values[filterName]}
            fetchFiltersRequestStates={fetchFiltersRequestStates}
            onChange={(val) => handleChangeFormValue(filterName, val)}
          />
        );
      case SEARCH_FILTER_INPUT_TYPES.SINGLE_CHOICE:
        return (
          <Filter
            key={filterName}
            isOpened={filter.isOpened}
            title={filterLabel}
            placeholder={`Select ${filterLabel}`}
            isSelect
            options={data}
            value={form.values[filterName]}
            fetchFiltersRequestStates={fetchFiltersRequestStates}
            onChange={(val) => handleChangeFormValue(filterName, val)}
          />
        );
      case SEARCH_FILTER_INPUT_TYPES.DATE_RANGE:
        return (
          <Filter
            key={filterName}
            isOpened={filter.isOpened}
            title={filterLabel}
            placeholder={`Select ${filterLabel}`}
            isDatePicker
            value={form.values[filterName]}
            fetchFiltersRequestStates={fetchFiltersRequestStates}
            onChange={(val) => handleChangeFormValue(filterName, val)}
          />
        );
      case SEARCH_FILTER_INPUT_TYPES.NUMERICAL_RANGE:
        return (
          <Filter
            key={filterName}
            isOpened={filter.isOpened}
            title={filterLabel}
            placeholder={`Select ${filterLabel}`}
            isRange
            minInputForRange={data.min}
            maxInputForRange={data.max}
            value={[form.values[filterName][0], form.values[filterName][1]]}
            fetchFiltersRequestStates={fetchFiltersRequestStates}
            onChange={(val) => handleChangeFormValue(filterName, val)}
            valueUnit={filter.unit}
          />
        );
      default:
        return null;
    }
  };

  let loadingTextNode;
  // let numberOfResultsNode;
  let applyFiltersBtnNode;
  let filtersNodeToRender;

  if (isEmpty(filters)) {
    filtersNodeToRender = Array.from(Array(5).keys()).map((item) => (
      <SkeletonLoader key={item} h="74px" w="100%" mt="8px" />
    ));
  } else {
    filtersNodeToRender = filters.filter((filter) => !filter.isHidden).map(renderFilter);
  }

  if (fetchFiltersRequestStates.rejected) {
    const errorMessage = fetchFiltersRequestStates.error;

    filtersNodeToRender = (
      <Flex direction="column" align="center" justify="center" py="56px">
        <ParaXSmallStrong c={theme.app.colors.TEXT_NEGATIVE_NORMAL}>
          {errorMessage || 'Failed to load page content. Please try again.'}
        </ParaXSmallStrong>
        <Button mt="12px" size={Button.SIZES.SMALL} onClick={getSearchFilters} text="Try again" />
      </Flex>
    );
  }

  if (fetchFiltersRequestStates.pending || fetchResultsCountRequestStates.pending) {
    // <Flex>
    //   <Loader size="xs" mr="8px" color={theme.app.colors.BG_NEUTRAL_STRONG} />
    //   <ParaXSmall style={{ whiteSpace: 'nowrap' }}>Checking...</ParaXSmall>
    // </Flex>
    loadingTextNode = (
      <Button size={Button.SIZES.X_SMALL} ml="16px" miw="150px">
        <Loader size="xs" color={theme.app.colors.TEXT_INVERTED} />
      </Button>
    );
  } else {
    let btnText = '';
    if (fetchResultsCountRequestStates.fulfilled) {
      btnText = `Show ${numberOfCandidatesFound} ${plur('Candidate', numberOfCandidatesFound)}`;
    } else {
      btnText = 'Show Candidate';
    }
    applyFiltersBtnNode = (
      <Button
        onClick={handleApplyFiltersBtnClick}
        size={Button.SIZES.X_SMALL}
        text={btnText}
        ml="16px"
        miw="150px"
        // disabled={fetchFiltersRequestStates.pending || fetchResultsCountRequestStates.pending}
      />
    );
  }

  // if (!hideResultsCount && fetchResultsCountRequestStates.fulfilled) {
  // <ParaXSmall>
  //   Showing {numberOfCandidatesFound} {plur('Candidate', numberOfCandidatesFound)}
  // </ParaXSmall>

  return (
    <Box bg={theme.app.colors.BG_SURFACE}>
      <Styles.Header>
        <Flex align="center">
          <Styles.HeaderIcon component={FilterIcon} />
          <TitleXSmall ml="8px">Filters</TitleXSmall>
        </Flex>
        <Styles.HeaderIcon
          component={CrossIcon}
          c={theme.app.colors.ICON_NEUTRAL_WEAK}
          style={{ cursor: 'pointer' }}
          onClick={handleCloseModal}
        />
      </Styles.Header>
      <Styles.FiltersContainer px="24px">{filtersNodeToRender}</Styles.FiltersContainer>
      <Divider />
      <Styles.Footer>
        <Button
          size={Button.SIZES.X_SMALL}
          color={Button.COLORS.NEUTRAL}
          variant={Button.VARIANTS.OUTLINED}
          onClick={handleClearAllFilters}
        >
          <Flex align="center">
            {numberOfFiltersNode}{' '}
            <LabelXSmall c={theme.app.colors.TEXT_NEUTRAL_NORMAL} fw={theme.app.fontWeights.medium}>
              Clear All
            </LabelXSmall>
          </Flex>
        </Button>

        <Flex align="center">{loadingTextNode ? loadingTextNode : applyFiltersBtnNode}</Flex>
      </Styles.Footer>
    </Box>
  );
};

export default SearchFiltersModal;
