import { noop } from 'lodash';
import { useEffect, useRef } from 'react';

/**
 * `setInterval` as a hook
 * @param {() => void | undefined} callback
 * @param {number | undefined} refreshInterval
 * @return {NodeJS.Timer}
 */
export default function useSetInterval(callback = noop, refreshInterval = 1000) {
  const timerRef = useRef(null);
  useEffect(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setInterval(callback, refreshInterval);
    return () => clearInterval(timerRef.current);
  }, [callback, refreshInterval]);
  return timerRef.current;
}
