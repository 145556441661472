import { isEmpty } from 'lodash-es';
import { get as getCookie } from 'es-cookie';

import * as utils from '~/utils';

const LOGGING_ENABLED = (() => {
  if (typeof window !== 'undefined') {
    if (utils.getEnv() !== 'PRODUCTION') {
      return true;
    }
  }
  return false;
})();

const makeHttpRequest = async (url, { method, headers: extraHeaders, ...rest }) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=utf-8',
    ...extraHeaders,
  };
  const accessToken = getCookie('access_token');
  if (accessToken) {
    headers['Authorization'] = `Bearer ${accessToken}`;
  }

  if (LOGGING_ENABLED) {
    // eslint-disable-next-line no-console
    console.debug(`HTTP ${method} ${url}`);
  }

  try {
    const response = await fetch(url, {
      cache: 'no-cache',
      credentials: 'same-origin',
      method,
      headers,
      ...rest,
    });
    const responseCopy = response.clone();

    // status in the range 200-299
    if (response.ok) {
      try {
        return await response.json();
      } catch (err) {
        if (err.name === 'AbortError') {
          throw err;
        }
        const error = new Error('Failed to parse successful response body as JSON');
        error.response = response;
        error.payload = undefined;
        throw error;
      }
    }

    // { response, payload?, payloadText? }
    const error = new Error(`Async fetch to ${url} failed with status code: ${response.status}`);
    error.response = response;
    try {
      error.payload = await response.json();
    } catch (err) {
      // Response does not contain JSON
      error.payload = undefined;
      error.payloadText = await responseCopy.text();
    }
    throw error;
  } catch (error) {
    // Request failed at network level
    if (LOGGING_ENABLED) {
      // eslint-disable-next-line no-console
      console.debug(`Async fetch to ${url} failed with:`, error);
    }
    throw error;
  }
};

const httpClient = {
  get: (url, options = null) =>
    makeHttpRequest(url, {
      method: 'GET',
      ...options,
    }),

  patch: (url, payload, options = null) =>
    makeHttpRequest(url, {
      method: 'PATCH',
      body: !isEmpty(payload) ? JSON.stringify(payload) : null,
      ...options,
    }),

  post: (url, payload, options) =>
    makeHttpRequest(url, {
      method: 'POST',
      body: !isEmpty(payload) ? JSON.stringify(payload) : null,
      ...options,
    }),

  put: (url, payload, options) =>
    makeHttpRequest(url, {
      method: 'PUT',
      body: !isEmpty(payload) ? JSON.stringify(payload) : null,
      ...options,
    }),

  postFile: (url, body, options) =>
    makeHttpRequest(url, {
      method: 'POST',
      body,
      ...options,
    }),

  delete: (url, options = null) =>
    makeHttpRequest(url, {
      method: 'DELETE',
      ...options,
    }),
};

export default httpClient;
