// Common
export const DATE_FORMATS = {
  default: 'd MMM yy, hh:mm a',
  defaultWithSeconds: 'd MMM yy, hh:mm:ss a',
  defaultWithTimezone: 'd MMM yyyy, hh:mm a xxx',
  defaultWithFullYear: 'd MMM yyyy, hh:mm a',
  dateWithTimeAndDay: 'EEEE, d MMM yyyy, hh:mm a',
  date: 'dd MMM yyyy',
  dateWithDay: 'EEEE, dd MMM yyyy',
  iso8601: "yyyy-MM-dd'T'HH:mm:ssXXX",
  time: 'hh:mm a',
  timeWithSeconds: 'hh:mm:ss a',
  nativeInput: `yyyy-MM-dd'T'HH:mm:ss`,
  csvDate: 'yyyy-MM-dd',
  csvTime: 'HH:mm:ss',
  csvDateAndTime: 'yyyy-MM-dd HH:mm:ss',
};

export const REQUEST_STATES = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  FULFILLED: 'FULFILLED',
  REJECTED: 'REJECTED',
};

export const MODAL_IDS = {
  V0: {
    JOB_APPLICATIONS_FILTER_QUERIES: 'V0_JOB_APPLICATIONS_FILTER_QUERIES',
    REPORT_ERROR: 'V0_REPORT_ERROR',
  },
  SEARCH_FILTERS_MODAL: 'SEARCH_FILTERS_MODAL',
  SEARCH_HISTORY: 'SEARCH_HISTORY',
  MESSAGE: 'MESSAGE',
  INVITE_TEAM_MATES_MODAL: 'INVITE_TEAM_MATES_MODAL',
  COLLECTION_ACTION_MODAL: 'COLLECTION_ACTION_MODAL',
  CONFIRMATION_MODAL: 'CONFIRMATION_MODAL',
  PIN_JOBS_MODAL: 'PIN_JOBS_MODAL',
  JOB_DETAILS_MODAL: 'JOB_DETAILS_MODAL',
  CONFIGURE_JOB_STAGES_MODAL: 'CONFIGURE_JOB_STAGES_MODAL',
  VIDEO_MODAL: 'VIDEO_MODAL',
};

export const MODAL_NAMES = {
  REQUESTING_ACCESS_TO_CONNECT_ATS: 'requesting_access_to_connect_ats',
  CREATE_COLLECTION: 'create_collection',
  EDIT_COLLECTION_DETAILS: 'edit_collection_details',
  DELETE_COLLECTION: 'delete_collection',
  INVITE_TEAM_TO_ORG: 'invite_team_to_org',
  INVITE_TEAM_TO_COLLECTION: 'invite_team_to_collection',
  REQUESTING_ACCESS_TO_IMPORT_EMAIL_APPLICATIONS_IN_BULK: 'requesting_access_to_import_email_applications_in_bulk',
};

export const JOB_TYPES_MAP = {
  FULL_TIME: 'Full Time',
  PART_TIME: 'Part Time',
  CONTRACT: 'Contract',
  INTERNSHIP: 'Internship',
  FREELANCE: 'Freelance',
  CONTRACT_TO_HIRE: 'Contract to Hire',
};

export const SUPPORT_EMAIL = 'support@kello.ai';

export const DEFAULT_SEARCH_VERSION = '3';

export const APP_PAGES = {
  LANDING: 'landing_page',
  APP_HOME: 'app_home_page',
  TALENT_POOL: 'talent_pool_page',
  COLLECTIONS: 'collections_home_page',
  JOBS: 'jobs_home_page',
};

export const SEARCH_FILTER_INPUT_TYPES = {
  SINGLE_CHOICE: 'SINGLE_CHOICE',
  MULTI_CHOICE: 'MULTI_CHOICE',
  DATE_RANGE: 'DATE_RANGE',
  NUMERICAL_RANGE: 'NUMERICAL_RANGE',
  TEXT: 'TEXT',
};

export const BASIC_INPUT_KEYS_FOR_SEARCH = {
  PAGE_NUMBER: 'pageNumber',
  SEARCH: 'search',
  SEARCH_QUERY: 'searchQuery',
  SEARCH_VERSION: 'searchVersion',
};

export const NON_FILTER_INPUT_KEYS_FOR_SEARCH = [
  BASIC_INPUT_KEYS_FOR_SEARCH.SEARCH,
  BASIC_INPUT_KEYS_FOR_SEARCH.SEARCH_QUERY,
  BASIC_INPUT_KEYS_FOR_SEARCH.SEARCH_VERSION,
  BASIC_INPUT_KEYS_FOR_SEARCH.PAGE_NUMBER,
];

export const CANDIDATE_ATS_ACTIONS = {
  LIKE: 'LIKE',
  REVIEW_LATER: 'REVIEW_LATER',
  ARCHIVE: 'ARCHIVE',
  SHORTLIST: 'SHORTLIST',
  SHARE: 'SHARE',
  ADD_TO_COLLECTION: 'ADD_TO_COLLECTION',
  ADD_TO_JOB: 'ADD_TO_JOB',
};

export const ADD_TO_JOB_ACTION_TYPES = {
  REVIEW_LATER: 'REVIEW_LATER',
  SHORTLIST: 'SHORTLIST',
  ADD_TO_JOB: 'ADD_TO_JOB',
};

export const JOB_TYPES = {
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED',
};

export const KELLO_STAGES = {
  SHORTLISTED: 'SHORTLISTED',
  REVIEW_LATER: 'REVIEW_LATER',
  UNREVIEWED: 'UNREVIEWED',
  REJECTED: 'REJECTED',
};

export const PROMPT_INPUT_SOURCE_TYPES = {
  JOBS: 'JOBS',
  TALENT_POOL: 'TALENT_POOL',
};

export const TALENT_POOL_APPLICATION_TYPES = {
  INBOUND_MAIL: 'INBOUND_MAIL',
  JOB_PORTAL: 'JOB_PORTAL',
};

export const DEMO_VIDEO_URL = 'https://www.youtube.com/embed/doTEaI75XSE?si=JHOkrCcHKzgabRws';

export const PAGE_SIZE_FOR_SEARCH_RESULTS = 100;

export const JOB_DETAILS_TABS = {
  CANDIDATES: 'candidates',
  ANALYTICS: 'analytics',
};

export const JOB_ANALYTICS_TYPES = {
  CANDIDATES_APPLIED: 'CANDIDATES_APPLIED',
  CANDIDATES_BY_SOURCES: 'CANDIDATES_BY_SOURCES',
  CANDIDATES_ADVANCED: 'CANDIDATES_ADVANCED',
};

export const SHOW_JOB_ANALYTICS = true;
