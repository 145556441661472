import { getEnv, isProductionHostname, isStagingHostname } from '~/utils';

const GOOGLE_CLIENT_ID_BY_ENVIRONMENT = {
  PRODUCTION: '521387537993-8fs84pvr9s2dh6n1cdedkffp2n5ihrjj.apps.googleusercontent.com',
  STAGING: '137457888237-qkgb7nm9qfkl63baheut9aucvj2f15ls.apps.googleusercontent.com',
  DEV: '356935705116-7fdma0qc7doegd039t2l7a7gnftkr4ds.apps.googleusercontent.com',
};

export const GOOGLE_LOGIN_CLIENT_ID = (() => {
  const { hostname } = location;
  if (isProductionHostname(hostname)) {
    return GOOGLE_CLIENT_ID_BY_ENVIRONMENT.PRODUCTION;
  }
  if (isStagingHostname(hostname)) {
    return GOOGLE_CLIENT_ID_BY_ENVIRONMENT.STAGING;
  }
  return GOOGLE_CLIENT_ID_BY_ENVIRONMENT.DEV;
})();

export const GOOGLE_PERMISSIONS_CLIENT_ID = (() => {
  const { hostname } = location;
  if (isProductionHostname(hostname)) {
    return GOOGLE_CLIENT_ID_BY_ENVIRONMENT.PRODUCTION;
  } else if (isStagingHostname(hostname)) {
    return GOOGLE_CLIENT_ID_BY_ENVIRONMENT.STAGING;
  }
  return GOOGLE_CLIENT_ID_BY_ENVIRONMENT.DEV;
})();

export const FORMSPARK_FORM_ID = '2u76IUKWq';

export const CHATWOOT_WEBSITE_TOKEN = 'bjfqXyr8RSHcJpMLAa2ymNYk';
export const CHATWOOT_IDENTITY_VALIDATION_KEY = 'kLEJhC6u9ABLrqR9jeezH89D';

export const getGoogleOAuthUrl = ({ hintEmail = '', isReconnect = false }) => {
  let redirectUri;
  let clientCode;

  const action = isReconnect ? 'reconnect' : 'integration';

  if (getEnv() === 'PRODUCTION') {
    redirectUri = `https://kello.ai/app/${action}/google`;
    clientCode = GOOGLE_CLIENT_ID_BY_ENVIRONMENT.PRODUCTION;
  } else if (getEnv() === 'STAGING') {
    redirectUri = `https://staging.kello.ai/app/${action}/google`;
    clientCode = GOOGLE_CLIENT_ID_BY_ENVIRONMENT.STAGING;
  } else {
    const { host } = location;
    const validHosts = ['localhost:8085', 'dev.kello.ai'];
    if (!validHosts.includes(host)) {
      redirectUri = `https://dev.kello.ai/app/${action}/google`;
    } else {
      redirectUri = `https://${host}/app/${action}/google`;
    }
    clientCode = GOOGLE_CLIENT_ID_BY_ENVIRONMENT.DEV;
  }
  return `https://accounts.google.com/o/oauth2/v2/auth?prompt=&access_type=offline&redirect_uri=${redirectUri}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fgmail.readonly&include_granted_scopes=true&login_hint=${hintEmail}&response_type=code&client_id=${clientCode}`;
};
