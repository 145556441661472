import styled from '@emotion/styled';
import { forwardRef } from 'react';
import { Tabs as MantineTabs } from '@mantine/core';

import { labelXSmallCss } from '~/components/typography';

const Root = styled(MantineTabs)``;

const TabsList = styled(MantineTabs.List)`
  &::before {
    border-color: transparent;
  }
`;

const Tab = styled(MantineTabs.Tab)`
  color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_NORMAL};
  padding: 12px 8px;

  & span {
    ${labelXSmallCss};
    line-height: 12px;
  }

  &:where([data-active]) {
    border-color: ${({ theme }) => theme.app.colors.BORDER_ACCENT_NORMAL};
    color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_STRONG};
  }

  &:where(:not([data-active])) {
    &:hover {
      border-color: transparent;
      color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_WEAK};
    }
  }

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.app.colors.TEXT_NEUTRAL_STRONG};
  }
`;

const TabPanel = styled(MantineTabs.Panel)``;

export const Tabs = forwardRef((props, ref) => {
  const { children, ...rest } = props;

  return (
    <Root ref={ref} keepMounted={false} {...rest}>
      {children}
    </Root>
  );
});

Tabs.List = TabsList;
Tabs.Tab = Tab;
Tabs.Panel = TabPanel;

export default Tabs;
