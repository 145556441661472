import queryString from 'query-string';
import { format, isDate } from 'date-fns';

import { DATE_FORMATS } from '~/constants';

import httpClient from './client';

const isValidSourcedAtValues = (values = []) => {
  return Array.isArray(values) && isDate(values[0]) && isDate(values[1]);
};

export const fetchTalentPoolInfo = ({ sourcedFrom } = {}, options = {}) => {
  const payload = {};

  if (sourcedFrom) {
    payload.sourcedFrom = sourcedFrom;
  }

  const query = queryString.stringify(payload);
  return httpClient.get(`/api/v1/talent-pool/info?` + query, options);
};

export const fetchTalentPoolInboundMailInfo = (options = {}) => {
  return httpClient.get('/api/v1/talent-pool-inbound-mail/info', options);
};

export const fetchTalentPoolInboundMailInfoForUser = (options = {}) => {
  return httpClient.get('/api/v1/talent-pool-inbound-mail/user/info', options);
};

export const fetchCandidateDetailsById = ({ candidateId, apiVersion = 'v2' }, options = {}) => {
  return httpClient.get(`/api/${apiVersion}/talent-pool/candidate/${candidateId}`, options);
};

export const fetchFiltersForTalentPool = (apiVersion = 'v2', options = {}) => {
  return httpClient.get(`/api/${apiVersion}/talent-pool/filters/`, options);
};

export const fetchFilterTalentPool = (payload, options = {}) => {
  const queryObj = {
    ...payload,
  };

  // TODO: Key name
  if (queryObj.experienceInYears?.length && Array.isArray(queryObj.experienceInYears)) {
    queryObj['experienceInYears.comparison'] = 'between';
    queryObj['experienceInYears.values'] = queryObj.experienceInYears.join(',');
  }

  if (isValidSourcedAtValues(queryObj.sourcedAt)) {
    queryObj['sourcedAt.comparison'] = 'between';
    queryObj['sourcedAt.values'] = queryObj.sourcedAt.map((date) => format(date, DATE_FORMATS.csvDate)).join(',');
  }

  delete queryObj['experienceInYears'];
  delete queryObj['sourcedAt'];

  const query = queryString.stringify(queryObj);

  return httpClient.get(`/api/v1/talent-pool?` + query, options);
};

export const fetchFilterTalentPoolCount = (payload, options = {}) => {
  const queryObj = { ...payload };

  if (queryObj.experienceInYears?.length && Array.isArray(queryObj.experienceInYears)) {
    queryObj['experienceInYears.comparison'] = 'between';
    queryObj['experienceInYears.values'] = queryObj.experienceInYears.join(',');
  }

  if (isValidSourcedAtValues(queryObj.sourcedAt)) {
    queryObj['sourcedAt.comparison'] = 'between';
    queryObj['sourcedAt.values'] = queryObj.sourcedAt.map((date) => format(date, DATE_FORMATS.csvDate)).join(',');
  }

  delete queryObj['experienceInYears'];
  delete queryObj['sourcedAt'];

  const query = queryString.stringify(queryObj);

  return httpClient.get(`/api/v2/talent-pool/candidates/count?` + query, options);
};

export const fetchTalentPoolHistory = ({ pageNumber, pageSize }, options = {}) => {
  const query = queryString.stringify({ pageNumber, pageSize });

  return httpClient.get(`/api/v1/talent-pool/search-history/?` + query, options);
};

export const updateTalentPoolHistory = ({ searchHistoryId, payload }, options = {}) => {
  return httpClient.patch(`/api/v1/talent-pool/search-history/${searchHistoryId}`, payload, options);
};

export const fetchTalentPoolHistoryById = ({ searchHistoryId, payload }, options = {}) => {
  return httpClient.get(`/api/v1/talent-pool/search-history/${searchHistoryId}`, payload, options);
};

export const fetchCollectionsForTalentPoolCandidates = (candidateId, options = {}) => {
  return httpClient.get(`/api/v1/talent-pool/${candidateId}/collections`, options);
};

export const likeTalentPoolCandidate = ({ candidateId, apiVersion = 'v2' }, options = {}) => {
  return httpClient.patch(`/api/${apiVersion}/talent-pool/candidate/${candidateId}/like`, options);
};

export const unlikeTalentPoolCandidate = ({ candidateId, apiVersion = 'v2' }, options = {}) => {
  return httpClient.delete(`/api/${apiVersion}/talent-pool/candidate/${candidateId}/like`, options);
};

export const shortlistTalentPoolCandidate = ({ candidateId, payload, apiVersion = 'v2' }, options = {}) => {
  return httpClient.patch(`/api/${apiVersion}/talent-pool/candidate/${candidateId}/shortlist`, payload, options);
};

export const reviewLaterTalentPoolCandidate = ({ candidateId, payload, apiVersion = 'v2' }, options = {}) => {
  return httpClient.patch(`/api/${apiVersion}/talent-pool/candidate/${candidateId}/review-later`, payload, options);
};
