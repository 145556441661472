import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mantine/core';

import { shimmer } from '~/styles/animations';

const SkeletonLoader = (props) => {
  return <Root {...props} />;
};

export default SkeletonLoader;

const Root = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.app.colors.BG_NEUTRAL_WEAKER};
    background: linear-gradient(
      -30deg,
      ${theme.app.colors.BG_NEUTRAL_WEAKER} 40%,
      ${theme.app.colors.BG_NEUTRAL_WEAKEST} 50%,
      ${theme.app.colors.BG_NEUTRAL_WEAKER} 60%
    );
  `}
  background-size: 300%;
  ${shimmer(1000)};
`;
