import { createContext, useCallback, useContext, useState } from 'react';
import { useImmer } from 'use-immer';
import { default as localStorageSlim } from 'localstorage-slim';

import { castToBoolean } from '~/utils';

const LS_KEY_TO_CHECK_IF_DEMO_VIDEO_POPUP_IS_DISMISSED = 'is_demo_video_popup_dismissed';

const LIST_OF_CANDIDATE_ARCHIVE_REASONS = [
  { value: 'Fit for another job posting', label: 'Fit for another job posting' },
  { value: 'For future opportunities', label: 'For future opportunities' },
  { value: 'Competency mismatch', label: 'Competency mismatch' },
  { value: 'Not CRED worthy', label: 'Not CRED worthy' },
  { value: 'Not convinced with the opportunity', label: 'Not convinced with the opportunity' },
  { value: 'Retained by current company', label: 'Retained by current company' },
  { value: 'Duplicate', label: 'Duplicate' },
];

const AppContext = createContext();
AppContext.displayName = 'AppContext';

const AppContextProvider = ({ children }) => {
  const [user, setUser] = useImmer(null);
  const [organisation, setOrganisation] = useImmer(null);
  const [orgUsers, setOrgUsers] = useImmer([]);
  const [numberOfOrgJobs, setNumberOfOrgJobs] = useImmer(0);
  const [isDevMode, setIsDevMode] = useState(false);
  const [isBetaMode, setIsBetaMode] = useState(true);
  const [candidateArchiveReasons, setCandidateArchiveReasons] = useImmer(LIST_OF_CANDIDATE_ARCHIVE_REASONS);
  const [jobApplicationStages, setJobApplicationStages] = useImmer([]); // TODO: Rename to shortlistStages
  const [defaultJobConfiguration, setDefaultJobConfiguration] = useImmer({
    archiveReasonId: null,
    shortlistStageId: null,
  });
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(true);
  const [isNavbarManuallyCollapsed, setIsNavbarManuallyCollapsed] = useState(false);
  const [isDemoVideoPopupDismissed, setIsDemoVideoPopupDismissed] = useState(
    castToBoolean(localStorageSlim.get(LS_KEY_TO_CHECK_IF_DEMO_VIDEO_POPUP_IS_DISMISSED)),
  );

  const dismissDemoVideoPopup = () => {
    localStorageSlim.set(LS_KEY_TO_CHECK_IF_DEMO_VIDEO_POPUP_IS_DISMISSED, 'true');
    setIsDemoVideoPopupDismissed(true);
  };

  const navbarWidth = useCallback(() => {
    if (isNavbarExpanded) {
      return '252px';
    }
    return '76px';
  }, [isNavbarExpanded])();

  const exposed = {
    user,
    setUser,

    organisation,
    setOrganisation,

    numberOfOrgJobs,
    setNumberOfOrgJobs,

    orgUsers,
    setOrgUsers,

    isDevMode,
    setIsDevMode,

    isBetaMode,
    setIsBetaMode,

    navbarWidth,
    isNavbarExpanded,
    setIsNavbarExpanded,

    isDemoVideoPopupDismissed,
    dismissDemoVideoPopup,

    candidateArchiveReasons,
    setCandidateArchiveReasons,

    jobApplicationStages,
    setJobApplicationStages,

    defaultJobConfiguration,
    setDefaultJobConfiguration,

    isNavbarManuallyCollapsed,
    setIsNavbarManuallyCollapsed,
  };

  return <AppContext.Provider value={exposed}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);

export default AppContextProvider;
