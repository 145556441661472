import { useCallback, useEffect, useState } from 'react';

import { padStart } from '~/utils';

import { useSetInterval } from '.';

/**
 * @param {{ id?: any, timeLeftMs?: number, refreshInterval?: number, startImmediate?: boolean  }}
 * @returns {{ isFinished: boolean, timeLeft: number, formattedTime: string, start: Function, stop: Function, setTimeLeftMs: (value: number) => void, isCountdownActive: boolean }}
 */
export default function useCountdown({ id, timeLeftMs, refreshInterval = 1000, startImmediate = true }) {
  const [timeLeft, setTimeLeft] = useState(timeLeftMs);
  const [keepCountdownActive, setKeepCountdownActive] = useState(startImmediate);

  useEffect(() => {
    setTimeLeft(timeLeftMs);
  }, [timeLeftMs, id]);

  const formatTime = useCallback((ms) => {
    try {
      ms = Math.max(ms, 0);
      let totalSeconds = Math.floor(ms / 1000);
      const seconds = totalSeconds % 60;
      const minutes = Math.floor((totalSeconds - seconds) / 60) % 60;
      const hours = Math.floor((totalSeconds - minutes * 60) / 3600);

      return `${padStart(hours, 2, '0')}:${padStart(minutes, 2, '0')}:${padStart(seconds, 2, '0')}`;
    } catch {
      return '';
    }
  }, []);

  const countdownCallback = useCallback(() => {
    if (keepCountdownActive) {
      setTimeLeft((current) => current - refreshInterval);
    }
  }, [keepCountdownActive]);

  useSetInterval(countdownCallback, refreshInterval);

  const isFinished = timeLeft <= 0;
  const formattedTime = isFinished ? '00:00:00' : formatTime(timeLeft);

  const setTimeLeftMs = useCallback((value) => {
    setTimeLeft(value);
  }, []);

  const start = useCallback(() => {
    setKeepCountdownActive(true);
  }, []);

  const stop = useCallback(() => {
    setKeepCountdownActive(false);
  }, []);

  return {
    isFinished,
    timeLeft,
    formattedTime,
    start,
    stop,
    setTimeLeftMs,
    isCountdownActive: !isFinished && keepCountdownActive,
  };
}
